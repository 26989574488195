<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button v-if="searchStatus" status="my-orange" @click="searchStatus = false">隐藏查询</vxe-button>
        <vxe-button v-if="!searchStatus" status="my-orange" icon="fa fa-search" @click="searchStatus = true">查询</vxe-button>
      </template>
    </vxe-toolbar>
    <div v-if="searchStatus" class="search">
      <vxe-form title-align="right" title-width="120" title-colon :data="listQuery" @submit="handleSearch(true)" @reset="handleSearch(true, true)">
        <vxe-form-item title="分类" span="6">
          <vxe-select v-model="listQuery.param.type" placeholder="请选择分类" clearable>
            <vxe-option
              v-for="(item, index) in dict.serviceFeedbackType"
              :key="index"
              :label="item"
              :value="index"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item title="状态" span="6">
          <vxe-select v-model="listQuery.param.status" placeholder="请选择状态" clearable>
            <vxe-option
              v-for="(item, index) in statusArray"
              :key="index"
              :label="item"
              :value="index"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <CustomList ref="CustomList" :columns="tableColumns" :list-query="listQuery" @getList="getList">
      <CustomPic slot="photos" slot-scope="{row}" :src="getPhotos(row.photos, 0)" :src-list="getPhotos(row.photos)" />
      <template slot="operating" slot-scope="{row}">
        <el-button v-permission="'FeedbackFeedbackDealButton'" v-if="row.status === 0" size="small" type="text" @click="handleDeal(row)">处理</el-button>
        <el-button v-permission="'FeedbackFeedbackDeleteButton'" size="small" type="text" @click="handleDelete(row.id)">删除</el-button>
      </template>
    </CustomList>
  </div>
</template>

<script>
import CustomList from '@/components/CustomList/index'
import { default as CustomTemplate } from '@/components/CustomList/CustomTemplate'
import { mapActions } from 'vuex'
export default {
  name: 'Feedback',
  components: { CustomList, ...CustomTemplate },
  data() {
    return {
      searchStatus: false,
      dict: {
        serviceFeedbackType: {}
      },
      statusArray: ['待处理', '已处理'],
      listQuery: {
        param: {
          hasTenant: true
        }
      },
      tableColumns: [
        {
          prop: 'typeName',
          title: '分类',
          minWidth: 100
        },
        {
          title: '图片',
          slot: 'photos'
        },
        {
          prop: 'description',
          title: '反馈内容',
          minWidth: 200
        },
        {
          prop: 'address',
          title: '详细地址',
          minWidth: 150
        },
        {
          title: '联系人',
          formatter: (row, column) => {
            let text = row.contactPerson || ''
            text += row.contactPerson && row.contactPhone ? ' <br/> ' : ''
            text += row.contactPhone || ''
            return text
          },
          minWidth: 150
        },
        {
          title: '状态',
          formatter: (row, column) => {
            return row.status in this.statusArray ? this.statusArray[row.status] : ''
          },
          minWidth: 100
        },
        {
          prop: 'feedbackContent',
          title: '处理结果',
          minWidth: 150
        },
        {
          prop: 'updateTime',
          title: '处理时间',
          minWidth: 160
        },
        {
          prop: 'createTime',
          title: '发布时间',
          minWidth: 160
        },
        {
          title: '操作',
          fixed: 'right',
          slot: 'operating',
          minWidth: 100
        }
      ]
    }
  },
  created() {
    this.getSysDictList('serviceFeedbackType')
  },
  methods: {
    ...mapActions(['getSysDictServe', 'feedbackPageList', 'feedbackUpdate', 'feedbackDelete']),
    getSysDictList(typeCode) {
      this.getSysDictServe({ typeCode }).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.msg)
          return
        }
        res.data.forEach(res => {
          this.dict[typeCode][res.dictPidVal] = res.dictDesc
        })
      })
    },
    handleSearch(resetCurrentPage = false, resetSearch = false) {
      if (resetSearch) {
        Object.assign(this.listQuery, this.$options.data.call(this).listQuery)
      }
      this.customListGetList(resetCurrentPage)
    },
    customListGetList(resetCurrentPage = false) {
      this.$refs.CustomList.getList(resetCurrentPage)
    },
    getList(params, callback) {
      this.feedbackPageList(params).then(response => {
        callback(response)
      }).catch(() => {})
    },
    handleDeal(row) {
      this.$prompt('', '处理', {
        inputPlaceholder: '请输入处理结果',
        confirmButtonText: '确认',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        const params = {
          address: row.address,
          contactPerson: row.contactPerson,
          contactPhone: row.contactPhone,
          description: row.description,
          feedbackContent: value,
          id: row.id,
          latitude: row.latitude,
          longitude: row.longitude,
          photoIds: row.photoIds,
          tenant: row.tenant,
          type: row.type,
          status: 1
        }
        this.feedbackUpdate(params).then(res => {
          if (res.code !== 200) {
            this.$message.error(res.msg)
            return
          }
          this.$message.success('操作成功')
          this.handleSearch()
        }).catch(() => {})
      }).catch(() => {})
    },
    handleDelete(id) {
      this.$confirm('确认删除吗？', '', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.feedbackDelete({ id }).then(res => {
          if (res.code !== 200) {
            this.$message.error(res.msg)
            return
          }
          this.$message.success('删除成功')
          this.handleSearch(true)
        }).catch(() => {})
      }).catch(() => {})
    },
    getPhotos(photos, index) {
      let photosArray = []
      if (photos) {
        photosArray = photos.split(',')
      }

      if (index === 0) {
        return photosArray.length ? photosArray[0] : ''
      } else {
        return photosArray
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper{
    overflow: auto;
  }
</style>
